<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @opened="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>学员调班</span>
    </div>
    <el-row>
      <el-col :span="6">
        学员：<span style="font-weight: bold">{{ studentInfo.userName }}</span>
      </el-col>
      <el-col :span="6" v-if="studentInfo.lessonAttendNum">
        进度：<span style="font-weight: bold">{{ studentInfo.lessonAttendNum }}/{{ studentInfo.lessonTotalNum }}</span>
      </el-col>
      <el-col :span="6" v-else>
        进度：<span style="font-weight: bold">{{ studentInfo.learnedCourseUnit }}/{{ studentInfo.totalCourseUnit }}</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 32px;">
      <el-input
        v-model.trim="classNameValue"
        placeholder="请输入班级名称"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>

      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
        class="tb-list"
      >
        <el-table-column label="班级名称" align="center" prop="name"></el-table-column>
        <el-table-column label="所属课程" align="center" prop="courseName"></el-table-column>
        <el-table-column label="老师" align="center" prop="teacherName"></el-table-column>
        <el-table-column label="学员数" align="center" prop="studentNum"></el-table-column>
        <el-table-column label="上课进度" align="center">
          <template slot-scope="scope">
            {{scope.row.finished}}/{{scope.row.total}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button @click="adjustTo(scope.row)" plain size="small" type="primary">调 入</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>

      <!-- adjust-date -->
<!--      <adjust-date-->
<!--        :show="adjustDialog"-->
<!--        @close="adjustDialog=false"-->
<!--        :user_id="user_id"-->
<!--        :classIdTo="classIdTo"-->
<!--        @updateView="updateView"-->
<!--        :classId="classIdCopy"-->
<!--      ></adjust-date>-->
    </el-row>
  </el-dialog>
</template>

<script>
import { getClassAll,changeClass } from '@/api/merchantClass'
// import adjustDate from '@/components/merchantClass/adjust-date'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    user_id: {
      default: '',
      type: String
    },
    studentInfo: {
      default: () => {
        return {}
      },
      type: Object
    },

    classId: {
      default: '',
      type: String
    }
  },
  // components: { adjustDate },
  data () {
    return {
      classNameValue: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      adjustDialog: false,
      classIdTo: '',
      classIdCopy: '',
    }
  },
  methods: {
    openDialog () {
      this.classIdCopy = this.$route.query.classID || this.studentInfo.classId
      this.getClassAll()
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getClassAll()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getClassAll()
    },
    search () {
      this.pagination.currentPage = 1
      this.getClassAll()
    },
    reset () {
      this.classNameValue = ''
    },

    // 获取所属收费课程的列表
    async getClassAll () {
      const res = await getClassAll({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        courseId:this.$route.query.courseId ? this.$route.query.courseId:this.studentInfo.courseId,
        productId: this.$route.query.productId,
        className: this.classNameValue,
        isFinish: false,
        exceptClassIds: [this.classIdCopy]
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 调入
    adjustTo (row) {
      // this.classIdTo = row.class_id
      // this.adjustDialog = true
      if( row.finished === row.total) {
        window.$msg('此班级已经结课',2)
        return false
      }
      if(parseInt(this.studentInfo.lessonAttendNum) === parseInt(row.finished)) {
        changeClass({
          classIdFrom: this.classIdCopy, // 原班级ID
          classIdTo: row.class_id, // 新班级ID
          studentId: this.user_id, // 学生ID
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('调班成功')
            this.close()
            this.updateView()
          }
        })
        return false
      }
      let message = ''
      if(parseInt(this.studentInfo.lessonAttendNum) > parseInt(row.finished)){
        message = '所选班级课程进度慢于学员的课程进度，确定调课？\n' +
          '\n' +
          '学员只会加入未上课的排课'
      }else{
        message = '所选班级课程进度快于学员的课程进度，确定调课？\n' +
          '\n' +
          '学员将自动消课以保证学员与班级进度一致'
      }
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeClass({
          classIdFrom: this.classIdCopy, // 原班级ID
          classIdTo: row.class_id, // 新班级ID
          studentId: this.user_id, // 学生ID
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('调班成功')
            this.close()
            this.updateView()
          }
        })
      }).catch(() => {

      });
    },

    close () {
      this.$emit('close')
    },

    updateView () {
      this.getClassAll()
      this.close()
      this.$emit('updateView')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
